<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Daftar Produk Hukum</span
        >
      </h3>
    </div>
    <div class="card-body pt-2 pb-0">
      <b-table
        borderless
        hover
        head-variant="light"
        busy.sync="isBusy"
        :items="items"
        :fields="fields"
        :sort-desc.sync="sortDesc"
        :per-page="perPage"
        id="myTable"
        ref="myTable"
        show-empty
        responsive
        :key="tableKey"
      >
        <template slot="empty">
          <div v-if="isBusy" class="text-center">
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <div v-else class="text-center">Tidak ada data yang ditampilkan</div>
        </template>
        <template #cell(aksi)="row" class="mr-1 align-middle">
          <b-button
            style="background-color: #9DD0FF; color: #18328D; font-weight: bold"
            @click="onClickView(row.item, row.button)"
            >Lihat</b-button
          >
        </template>
      </b-table>
      <b-pagination
        pills
        align="center"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        prev-text="Prev"
        next-text="Next"
        aria-controls="myTable"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TabelProdukHukum",
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      totalItems: 0,
      isBusy: false,
      sortDesc: true,
      fields: [
        {
          key: "jenis_perundangan.name",
          label: "Jenis Perundangan",
          sortable: true
        },
        {
          key: "judul",
          sortable: true
        },
        {
          key: "tahun",
          sortable: true
        },
        {
          key: "aksi",
          sortable: false
        }
      ],
      items: [],
      total: null,
      tableKey: 0
    };
  },
  watch: {
    currentPage: {
      handler: function() {
        this.fetchData();
      }
    }
  },
  mounted() {
    this.isBusy = true;

    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(process.env.VUE_APP_URL_LOCAL + `/dokumentasi`, {
          params: {
            sort: JSON.stringify([{ selector: "id", desc: true }]),
            take: this.perPage,
            skip: (this.currentPage - 1) * this.perPage
          }
        })
        .then((items) => {
          this.items = items.data.data;
          this.totalItems = items.data.totalCount;
          this.isBusy = false;

          this.tableKey += 1
        });
    },
    rowClass(item, type) {
      if (!item || type === "row") return "vert-aligned";
    },
    onClickView(item) {
      this.$router.push({ name: "DetailDokumentasi", params: { item } });
    }
  }
};
</script>
