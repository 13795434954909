<template>
  <div>
    <!--begin::Dashboard-->
    <!-- start:Card Infografik -->
    <!--    <div class="row justify-content-md-center">
      <div class="col-md-3">
        <CardWidget1></CardWidget1>
      </div>
      <div class="col-md-3">
        <CardWidget2></CardWidget2>
      </div>
      <div class="col-md-3">
        <CardWidget3></CardWidget3>
      </div>
      <div class="col-md-3">
        <CardWidget4></CardWidget4>
      </div>
    </div>-->
    <!-- end:Card Infografik -->

    <div class="row">
      <div class="col-md-12">
        <TabelProdukHukum />
      </div>
    </div>

    <!--    <div class="row" v-if="loggedIn">
      <div class="col-md-12">
        <TableDashboard></TableDashboard>
      </div>
    </div>-->

    <div class="row">
      <div class="col-xl-4">
        <div
          class="card card-custom bgi-no-repeat gutter-b card-stretch"
          style="background-position: right top; background-size: 35% auto; background-image: url(media/svg/shapes/abstract-4.svg)"
        >
          <!--begin::Body-->

          <div class="card-body" v-if="loggedIn">
            <router-link :to="'/tabel'" style="color: #000000">
              <span class="svg-icon svg-icon-4x d-block">
                <inline-svg src="media/svg/custom/mace.svg" />
              </span>
              <p class="card-title font-judul">
                PERUNDANG-UNDANGAN
              </p>
              <p class="text-dark-75 font-weight-bolder font-size-lg">
                Data dan informasi penyusunan produk hukum
              </p>
            </router-link>
          </div>

          <div class="card-body" v-else>
            <router-link :to="{ name: 'login' }" style="color: #000000">
              <span class="svg-icon svg-icon-4x d-block">
                <inline-svg src="media/svg/custom/mace.svg" />
              </span>
              <p class="card-title font-judul">
                PERUNDANG-UNDANGAN
              </p>
              <p class="text-dark-75 font-weight-bolder font-size-lg">
                Data dan informasi penyusunan produk hukum
              </p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div
          class="card card-custom bgi-no-repeat gutter-b card-stretch"
          style="background-position: right top; background-size: 35% auto; background-image: url(media/svg/shapes/abstract-4.svg)"
        >
          <!--begin::Body-->

          <div class="card-body" v-if="loggedIn">
            <router-link :to="{ name: 'TabelAdvokasi' }" style="color: #000000">
              <span class="svg-icon svg-icon-4x d-block">
                <img src="media/products/books.png" class="h-25" alt="books" />
              </span>
              <p class="card-title font-judul">
                ADVOKASI
              </p>
              <p class="text-dark-75 font-weight-bolder font-size-lg">
                Data dan Informasi progres penanganan masalah hukum, litigasi,
                non litigasi
              </p>
            </router-link>
          </div>

          <div class="card-body" v-else>
            <router-link :to="{ name: 'login' }" style="color: #000000">
              <span class="svg-icon svg-icon-4x d-block">
                <img src="media/products/books.png" class="h-25" alt="books" />
              </span>
              <p class="card-title font-judul">
                ADVOKASI
              </p>
              <p class="text-dark-75 font-weight-bolder font-size-lg">
                Data dan Informasi progres penanganan masalah hukum, litigasi,
                non litigasi
              </p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div
          class="card card-custom bgi-no-repeat gutter-b card-stretch"
          style="background-position: right top; background-size: 35% auto; background-image: url(media/svg/shapes/abstract-4.svg)"
        >
          <!--begin::Body-->
          <router-link
            :to="{ name: 'ListDokumentasiCard' }"
            style="color: #000000"
          >
            <div class="card-body">
              <span class="svg-icon svg-icon-4x d-block">
                <inline-svg src="media/svg/custom/agenda-dashboard.svg" />
              </span>
              <p class="card-title font-judul">
                Dokumentasi dan Informasi
              </p>
              <p class="text-dark-75 font-weight-bolder font-size-lg">
                Data dan informasi penyusunan produk hukum
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!--    end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
/*import CardWidget1 from "@/view/pages/admin/card/CardWidget1";
import CardWidget2 from "@/view/pages/admin/card/CardWidget2";
import CardWidget3 from "@/view/pages/admin/card/CardWidget3";
import CardWidget4 from "@/view/pages/admin/card/CardWidget4";*/
// import TableDashboard from "@/view/pages/admin/table/TableDashboard";
// import { mapGetters } from "vuex";
import TabelProdukHukum from "@/view/pages/admin/table/TabelProdukHukum";
// import UserService from "@/core/services/user.service";
// import EventBus from "@/core/common/EventBus";
import VueJwtDecode from "vue-jwt-decode";
// import TokenService from "@/core/services/token.service";

export default {
  name: "dashboard",
  components: {
    TabelProdukHukum
    // TableDashboard
    // CardWidget1,
    // CardWidget2,
    // CardWidget3,
    // CardWidget4
  },
  data() {
    return {
      user: {},
      list: [
        {
          title: "3669",
          desc: "ReactJs, HTML",
          users: "4600 Users",
          size: "5.4MB",
          svg: "media/svg/icons/Shopping/Cart3.svg",
          class: "success"
        },
        {
          title: "Popular Authors",
          desc: "Python, MySQL",
          users: "7200 Users",
          size: "2.8MB",
          svg: "media/svg/icons/Layout/Layout-4-blocks.svg",
          class: "danger"
        },
        {
          title: "New Users",
          desc: "Laravel, Metronic",
          users: "890 Users",
          size: "1.5MB",
          svg: "media/svg/icons/Communication/Group.svg",
          class: "info"
        },
        {
          title: "Active Customers",
          desc: "AngularJS, C#",
          users: "6370 Users",
          size: "890KB",
          svg: "media/svg/icons/Home/Library.svg",
          class: "warning"
        },
        {
          title: "Bestseller Theme",
          desc: "ReactJS, Ruby",
          users: "354 Users",
          size: "500KB",
          svg: "media/svg/icons/Shopping/Box2.svg",
          class: "primary"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);

    /*if (this.currentUser) {
      this.getJwt();
    }*/

    /*UserService.getUserBoard().then(
      response => {
        console.log(response);
      },
      error => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );*/
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    // ...mapGetters(["isLoggedIn"])
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
    getUserDetails() {
      // get token from localstorage
      try {
        let token = localStorage.getItem("token");
        if (token) {
          //decode token here and attach to the user object
          this.user = VueJwtDecode.decode(token);
        }
      } catch (error) {
        // return error in production env
        console.log(error, "error from decoding token");
      }
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created() {
    this.getUserDetails();
  }
};
</script>

<style scoped>
.font-judul {
  font-weight: 700;
  line-height: 27px;
  font-size: 18px;
  margin-top: 20px;
  text-transform: uppercase;
}
</style>
